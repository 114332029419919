*{
    font-family: "Montserrat", sans-serif;
}

img.r3play-image{
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height:auto;
    opacity:0.9;
    border: 2px solid #529fcc;
}

div.projects-carousel-caption{
    font-size:0px;
    width: 100%;
    height:300px;
    max-width: 750px;
    opacity:0;
    margin: 0 auto;
    background-color: #529fcc;
    /* width: 100%; */
    padding:7.50rem 30px;
    right:0;
    left:0;
    /* bottom:0.25rem; */
    /* border-radius: 15px; */
}

.r3play-carousel-heading{
    font-size:18px;
    margin-bottom:20px;
}

/* .carousel-heading:hover{
    font-size:14px;
    transition: font-size 200ms ease-in-out;
} */

.r3play-carousel-caption:hover{
    font-size:18px;
    transition: font-size 200ms ease-in-out;
    opacity:0.9;
}

.carousel-indicators {
bottom:-20px !important;
}

.carousel-indicators [data-bs-target]{
    background-color: #fff !important;
}

.carousel-control-prev-icon{
    background-image: url("../img/arrow-ios-back.svg") !important;

}

.carousel-control-next-icon{
    background-image: url("../img/arrow-ios-forward.svg") !important;

}





.fa-code, .fa-up, .fa-circle{
    color: white;
    font-size:24px;
    padding-right: 16px;
}

.fa-code:hover,
.fa-up:hover,
.fa-circle:hover {
    color: black;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 980px){
    img.r3play-image{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 800px;
        height:auto;
        opacity:0.9;
        border: 2px solid #529fcc;
    }
    div.r3play-carousel-caption{
        display:none;
        font-size:0px;
        margin: -10px auto;
        width: 100%;
        height:255px;
        /* padding:5px; */
        /* max-width: 194px; */
        left:0;
        right:0;
    }
    /* div.r3play-carousel-caption:hover{
        font-size:14px;
        -webkit-transition:font-size 0.2s ease-in-out;
    -o-transition:font-size 0.2s ease-in-out;
    transition:font-size 0.2s ease-in-out;
    } */

    h3.r3play-carousel-heading{
        font-size:14px;
        margin-top:95px;
    }
    .fa-code, .fa-up, .fa-circle{
        color: white;
        font-size:18px;
        padding-right: 16px;
    }
   
} 

@media screen and (max-width: 600px){
    img.r3play-image{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 800px;
        height:auto;
        opacity:0.9;
        border: 2px solid #529fcc;
    }
    cite{
        font-size:0px;
    }
    
    div.r3play-carousel-caption{
        opacity:0;
        font-size:0px;
        margin: -20px auto;
        width: 100%;
        height:250px;
        /* padding:0; */
        /* max-width: 194px; */
        left:0;
        right:0;
    }
    div.r3play-carousel-caption:hover{
        opacity:0.9;
        -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
        
    }

    h3.r3play-carousel-heading{
        font-size:14px;
        margin-top:14px;
        
    }
    .fa-code, .fa-up, .fa-circle{
        color: white;
        font-size:18px;
        padding-right: 16px;
    }
   

   
} 