

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mute-button{
  position: fixed;
  top: 87%;
  right:0;
  height:50px;
  width: 45px;
}

.sound-tgl-icon{
  padding:8px 10px 10px 4px;
  margin-left: -10px;
}

.dark .moon{
  color: cornflowerblue !important;
}

.dark .bolt, .old .bolt, .bolt{
  color: crimson !important;
}

.old .modal-body{
  background-color: rgb(182, 177, 177) !important;
}

.reduced-motion div.modal-dialog, .reduced-motion div.modal-content, .reduced-motion div.modal-body{
  background-color: rgb(0, 0, 0) !important;
  color: whitesmoke !important;
}

.old .svg-inline--fa.fa-circle-info.fa-fade.fa-lg.pr-2{
  color: rgb(25, 83, 192) !important;
}

.old .got-it-button{
  background-color: rgb(25, 83, 192) !important;

}

.old .got-it-button:hover{
  background-color: rgb(51, 102, 196) !important;

}

.sun.fa-sun{
  color: #ebbb43 !important;
}

.old .sun.fa-sun{
  color: rgb(239, 255, 58) !important;
}

.system{
  color: #000;
}

.dark .system{
  color: whitesmoke !important;
}

.custom-dropdown-title { font-size: 18px; /* Change this to your desired font size */ color: #007bff; /* Change this to your desired color */ font-weight: bold; /* Change this to your desired font weight */ }