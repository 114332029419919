*{
    font-family: "Montserrat", sans-serif;
}

.slide-plus{
    display:none;
}

img.projects-testim-image{
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height:auto;
    opacity:0.9;
    border: 2px solid #529fcc;
}

div.projects-carousel-caption{
    font-size:0px;
    width: 100%;
    height:360px;
    max-width: 750px;
    opacity:0;
    margin: 0 auto;
    background-color: #529fcc;
    /* width: 100%; */
    padding:7.50rem 30px;
    right:0;
    left:0;
    /* bottom:0.25rem; */
    /* border-radius: 15px; */
}

.projects-carousel-heading{
    font-size:20px;
    margin-bottom:20px;
}




.projects-carousel-caption:hover,
    .projects-carousel-caption:active{
    font-size:18px;
    transition: font-size 200ms ease-in-out;
    opacity:0.9;
}

.carousel-indicators {
bottom:-20px !important;
}

.carousel-indicators [data-bs-target]{
    background-color: #fff !important;
}

.carousel-control-prev-icon{
    background-image: url("../img/arrow-ios-back.svg") !important;

}

.carousel-control-next-icon{
    background-image: url("../img/arrow-ios-forward.svg") !important;

}


.projects-work-links{
    display:flex;
    /* height:40px; */
    width:100%;
    justify-content: center;
    flex-direction: row;
    margin:20px auto 0 auto;

}


.fa-code, .fa-up, .fa-circle, .fa-clipboard-check{
    color: white;
    font-size:24px;
    padding: 12px;
}

.fa-code:hover,
.fa-up:hover,
.fa-circle:hover,
.fa-clipboard-check:hover 
 {
    color: black;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 980px){
    .slide-plus{
        display:block;
        position: absolute;
        right:46%;
        top: 18px;
        border-radius:16px;
        background-color: #000;
        padding:10px;
        opacity:0.5;
        
    }

    .slide-plus:hover{
      
        opacity:0.9;
        
    }
    img.projects-testim-image{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 800px;
        height:auto;
        opacity:0.9;
        border: 2px solid #529fcc;
    }
    div.projects-carousel-caption{
        font-size:0px;
        margin: -10px auto;
        width: 100%;
        height:255px;
        /* padding:5px; */
        /* max-width: 194px; */
        left:0;
        right:0;
    }
    div.projects-carousel-caption:hover,
        div.projects-carousel-caption:active{
        font-size:14px;
        -webkit-transition:font-size 0.2s ease-in-out;
    -o-transition:font-size 0.2s ease-in-out;
    transition:font-size 0.2s ease-in-out;
    }

    h3.projects-carousel-heading{
        font-size:18px;
        margin-top:-35px;
    }
    .fa-code, .fa-up, .fa-circle{
        color: white;
        font-size:18px;
        padding-right: 16px;
    }
    div.projects-work-links{
        display:flex;
        height:40px;
        width:90px;
        flex-direction: row;
        /* margin-top:20px; */
        margin:10px auto 0 auto;
    
    }
  
} 

@media screen and (max-width: 600px){
    .slide-plus{
        display:block;
        position: absolute;
        right:46%;
        top: 18px;
        border-radius:16px;
        background-color: #000;
        padding:10px;
        opacity:0.5;
        
    }

    .slide-plus:hover{
      
        opacity:0.9;
        
    }
    
    img.projects-testim-image{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 800px;
        height:auto;
        opacity:0.9;
        border: 2px solid #529fcc;
    }
    cite{
        font-size:0px;
    }
    
    div.projects-carousel-caption{
        opacity:0;
        font-size:0px;
        margin: 0px auto;
        width: 100%;
        height:250px;
        left:0;
        right:0;
    }
    div.projects-carousel-caption:hover,
        div.projects-carousel-caption:active{
        opacity:0.9;
        -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;

    }

    .dark h3.projects-carousel-heading{
        color: whitesmoke !important;
        
    }

    h3.projects-carousel-heading{
        font-size:18px;
        
        
    }
    .fa-code, .fa-up, .fa-circle{
        color: white;
        font-size:18px;
        padding-right: 16px;
    }
    div.projects-work-links{
        display:flex;
        height:30px;
        width:90px;
        flex-direction: row;
        /* margin-top:20px; */
        margin:14px auto 0 auto;
    
    }

    
    a.projects-more{
        font-size:14px;
        color: #000 !important;
    }
    a.testim-more.more{
        color: rgb(242, 255, 0) !important;
    }
} 

@media screen and (max-width: 400px){
    div.carousel-caption{
        font-size:0px;
        margin: 0 auto;
        width:100%;
        height:100%;
        max-width:100%;
        max-height:100%;
        /* height:200px; */
        padding:0 !important;
        left:0;
        right:0;
        top:0;
        bottom:0;
        opacity:0;
    }
    .carousel-caption:hover{
        font-size:12px;
        border-radius:0;
        display: flex;
        justify-content:center !important;
  flex-direction: column !important;
        opacity:0.9;
        -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    }
    h3.projects-carousel-heading{
        font-size:16px;
        
    }
    a.projects-more{
        font-size:14px;
        color: #000 !important;
    }
} 