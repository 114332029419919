*{
    font-family: "Montserrat", sans-serif;
}

img.r3play-image{
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height:auto;
    opacity:0.9;
    border: 2px solid #529fcc;
}

div.projects-carousel-caption{
    font-size:0px;
    width: 100% !important;
    height:100% !important;
    max-width: 800px !important;
    opacity:0;
    margin: 0 auto;
    background-color: #529fcc;
    /* width: 100%; */
    padding:0 0;
    right:0;
    left:0;
    /* bottom:0.25rem; */
    /* border-radius: 15px; */
}

.r3play-carousel-heading{
    font-size:18px;
    margin-bottom:20px;
}

/* .carousel-heading:hover{
    font-size:14px;
    transition: font-size 200ms ease-in-out;
} */

.r3play-carousel-caption:hover{
    font-size:18px;
    transition: font-size 200ms ease-in-out;
    opacity:0.9;
}

.carousel-indicators {
bottom:-20px !important;
}

.carousel-indicators [data-bs-target]{
    background-color: #fff !important;
}

.carousel-control-prev-icon{
    background-image: url("../img/arrow-ios-back.svg") !important;

}

.carousel-control-next-icon{
    background-image: url("../img/arrow-ios-forward.svg") !important;

}





.fa-code, .fa-up, .fa-circle{
    color: white;
    font-size:24px;
    padding-right: 16px;
}

.fa-code:hover,
.fa-up:hover,
.fa-circle:hover {
    color: black;
    -webkit-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

@media screen and (max-width: 980px){
    img.r3play-image{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 800px;
        height:auto;
        opacity:0.9;
        border: 2px solid #529fcc;
    }
    div.r3play-carousel-caption{
        display:none;
        font-size:0px;
        margin: -10px auto;
        width: 100%;
        height:255px;
        /* padding:5px; */
        /* max-width: 194px; */
        left:0;
        right:0;
    }
    /* div.r3play-carousel-caption:hover{
        font-size:14px;
        -webkit-transition:font-size 0.2s ease-in-out;
    -o-transition:font-size 0.2s ease-in-out;
    transition:font-size 0.2s ease-in-out;
    } */

    h3.r3play-carousel-heading{
        font-size:14px;
        margin-top:95px;
    }
    .fa-code, .fa-up, .fa-circle{
        color: white;
        font-size:18px;
        padding-right: 16px;
    }
    .social-container{
        padding-top: 0 !important;
    }
   
} 

@media screen and (max-width: 600px){
    img.r3play-image{
        margin: 0 auto;
        /* width: 100%; */
        max-width: 800px;
        height:auto;
        opacity:0.9;
        border: 2px solid #529fcc;
    }
    cite{
        font-size:0px;
    }
    
    

    h3.r3play-carousel-heading{
        font-size:14px;
        margin-top:14px;
        
    }
    .fa-code, .fa-up, .fa-circle{
        color: white;
        font-size:18px;
        padding-right: 16px;
    }
   
.projects-more{
    font-size:0px !important;
}

.fa-circle{
    color: #000 !important;
    }

.fa-circle:hover{
    color: whitesmoke !important;
    }

.dark .fa-circle{
color: #529fcc !important;
}

.dark .fa-circle:hover{
    color: whitesmoke !important;
    }
    div.active.carousel-item div.carousel-caption.testim-caption.carousel-caption h3.carousel-heading.testim-heading{
        text-align: center !important;
    }
    .testim-more.more{
        padding-left: 0 !important;
    }
    .carousel-heading{
        /* font-size:18px; */
        /* padding: 0 14px 0 14px; */
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    div.carousel-caption{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
} 

