.main-footer,
.footer-404 {
    /* margin: 1rem auto; */
    /* max-width: 800px; */
    /* background-color: #529fcc; */
    color: #000;
    --button-text: #000;
    --button-bg: transparent;
    transition: background-color 0.3s ease;
}

.content {
    flex: 1;
    /* Allows the content to take up available space, pushing the footer to the bottom */
}

.footer-links-container {
    display: flex;
    flex-direction: row;
}

.dark .footer-links {
    color: #529fcc !important;

}

.logo-pulse {
    animation: pulse 1s infinite;
    border-radius: 50%;
    width: 20em;
}

@keyframes pulse {
    from {
        box-shadow: 0 0 0 0 rgba(198, 182, 179, 0.85);
    }

    to {
        box-shadow: 0 0 0 30px rgba(201, 48, 48, 0);
    }
}

.footer-links {
    display: inline;
    margin: 0 14px;

}

.dark .copyright {
    color: whitesmoke;
}



.main-footer.dark,
.footer-404.dark {
    background-color: black;
    color: #fff;
    --button-text: #fff;
    --button-bg: transparent;
    transition: background-color 0.3s ease;
}


.main-footer,
.footer-404 {
    font-family: "Montserrat", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* position: fixed; */
    width: 100%;
    bottom: 0;
    /* margin-top: auto; */
    background-color: #529fcc;
    padding-bottom: 16px;
    /* color: white; */
    height: 150px;
    /* padding-top:26px; */
    /* margin-top:96px; */

}

.main-footer p,
.footer-404 p {
    color: #000000;
}

.main-footer.dark p,
.footer-404.dark p {
    color: #fff;
}

.main-footer.dark .social-container,
.footer-404.dark .social-container {
    background-color: cornflowerblue;
}

.main-footer.dark .sidebar-toggler,
.footer-404.dark .sidebar-toggler {
    background-color: transparent !important;
    border: 0px solid black;
    animation: pulse 1s infinite;
    /* border-radius: 50%; */
    /* width: 20em; */

}


.main-footer .sidebar-toggler,
.footer-404 .sidebar-toggler {
    background-color: transparent !important;
    border: 0px solid black;
    animation: pulse 1s infinite;
    /* border-radius: 50%; */
    /* width: 20em; */

}

.sidebar-pulse,
.dark .sidebar-pulse {
    animation: pulse 1s 3 !important;

}

@keyframes pulse {
    from {
        box-shadow: 0 0 0 0 rgba(198, 182, 179, 0.85);
    }

    to {
        box-shadow: 0 0 0 30px rgba(201, 48, 48, 0);
    }
}

.page-header.dark select {
    background-color: #000;
    color: white;
}

.page-header.dark a.dropdown-item,
.page-header.dark a.nav-cases,
.page-header.dark #collapsible-nav-dropdown {
    background-color: #000;
    color: #fff;
}

.page-header.dark a.dropdown-item:hover,
.page-header.dark a.nav-cases:hover {
    background-color: #000 !important;
    color: #fff;
}

.social-container.dark a:hover {
    color: white !important;
}

/* .open-sidebar, .close-sidebar{
    color: black;
} */

.fa-caret-right {
    padding: 4px 10px 4px 3px;
    font-size: 34px;
    color: rgb(255, 166, 58);
    cursor: pointer;
background-color: #292929;
    -webkit-animation: 4s close-sidebar infinite;
    animation: 4s close-sidebar infinite;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.dark .fa-caret-right {
    font-size: 34px;
    color: white;
background-color: cornflowerblue;
    cursor: pointer;
    -webkit-animation: 4s close-sidebar infinite;
    animation: 4s close-sidebar infinite;
}

.sidebar-close {

    position: fixed;
    bottom: 10vh;
    z-index: 30;
    left: -8px;
    height: 20px;
    width: 20px;

}

.fixed-ltr { direction: ltr !important; /* Ensures text direction is left-to-right */ }


.dark .sidebar-toggler {

    background-color: #4d95bf !important;

}

.blog-link{
    margin-bottom: 26px !important;
    padding-right: 12px !important;
}

.social-container {
    padding-top: 38px !important;
    padding-bottom: 20px;
    /* align-items: center; */
    position: fixed;
    bottom: 0;
    top: 0;
    /* left: -37px; */
    left: 0px;
    height: 100%;
    width: 42px;
    /* width: 100%; */
    opacity: 0.8;
    background-color: #292929;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: space-evenly;
    align-content: space-evenly;


}

.sidebar-toggler {
    cursor: pointer;
}

.social-container:hover {
    /* left:0; */
    opacity: 1;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.social-container a {
    margin-bottom: 28px;
    padding: 10px;

}

/* .social-container a.medium {
    margin-bottom: 24px !important;
    padding: 0px;
margin-top: 24px !important;
}


.blog-link{
    margin-bottom: 12px !important;
}

.social-container .fa-medium {
    margin-bottom: 28px;
    padding-left: 0 !important;
    padding: 10px;


}

a.instagram{
    margin-top: 20px !important;
} */


.social-information {
    margin: 25px auto 16px;
    color: #1c7488;
}

.dark .r3play-headings {
    color: #529fcc;
}

.dark .projects-main-heading {
    color: #529fcc;
}


.dark .carousel-caption {
    background-color: #000;
    color: whitesmoke;
}

.dark .carousel-heading {
    background-color: #000;
    color: #529fcc;
}

.dark .more, .dark .testim-more {
    background-color: #000;
    color: #529fcc !important;
}

.dark .themeOne {
    background-color: #424242 !important;
    color: #529fcc;
}

.dark .form-heading {
    color: whitesmoke;
}

.dark .captions {
    color: #529fcc;
}

.dark input.w-full {
    background-color: grey !important;

}

.dark input.w-full:focus {
    background-color: whitesmoke !important;
    outline: 1px solid #529fcc;


}

.dark input.w-full::placeholder {
    color: whitesmoke;
}

.dark input.w-full:focus::placeholder {
    color: black;
    ;
}

.dark .label-required {
    color: yellow;
    padding-right: 2px;
}

.dark .contact-form-tip {
    color: #529fcc;
    ;
    padding-right: 2px;
}

.contact-form-tip span {
    /* color: #529fcc;;  */
    padding-right: 3px;
}

.dark .contact-form-tip span {
    /* color: #529fcc;;  */
    padding-right: 3px;
}

.dark .form-asterisk {
    color: yellow;
}

.dark .carousel-caption cite {
    background-color: #000;
    color: whitesmoke;
}

/* .dark .footer-404{
    background-color:#000;
    color: whitesmoke;
} */

.dark .video-image-heading,
.dark .technologies-heading {
    background-color: #000;
    padding: 15px 0;
}

.dark .skills-paragraphs span {
    background-color: #000;
}

.dark .powered {
    color: whitesmoke;
}

.impressum-links {
    color: #529fcc;
}

.page-header a:hover {
    color: black !important;
}

.dark .fa-envelope:hover,
.dark .fa-skype:hover,
.dark .fa-linkedin:hover,
.dark .fa-twitter:hover,
.dark .fa-github:hover,
.dark .fa-instagram:hover,
.dark .fa-arrow-up-right:hover,
.dark .fa-medium:hover,
.dark .fa-dev:hover,
.dark .fa-code:hover,
.dark .fa-up:hover,
.dark .fa-circle:hover,
.dark .fa-clipboard-check:hover,
.dark .projects-carousel-heading,
.dark .projects-more,
.dark .page-header a:hover {
    color: #292929 !important;
    transition: color 0.2s ease, translate 0.3s linear !important;

}

.old .fa-envelope:hover,
.old .fa-skype:hover,
.old .fa-linkedin:hover,
.old .fa-twitter:hover,
.old .fa-github:hover,
.old .fa-instagram:hover,
.old .fa-medium:hover,
.old .fa-arrow-up-right:hover,
.old .fa-dev:hover {
    color: cornflowerblue !important;
    
    transition: color 0.2s ease, translate 0.3s linear !important;
}

.fa-envelope:hover,
.fa-skype:hover,
.fa-linkedin:hover,
.fa-twitter:hover,
.fa-github:hover,
.fa-instagram:hover,
.fa-medium:hover,
.fa-arrow-up-right:hover,
.fa-dev:hover {
    color: cornflowerblue !important;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    translate: 0px -4px;
    -webkit-transition: color 0.2s ease, translate 0.3s linear;
    -o-transition: color 0.2s ease, translate 0.3s linear;
    transition: color 0.2s ease, translate 0.3s linear;
}

.fa-envelope.contact-icons,
.fa-linkedin.contact-icons {
    color: #529fcc;
}

.fa-envelope.contact-icons:hover,
.fa-linkedin.contact-icons:hover {
    color: #3888b6;
}

.fa-envelope,
.fa-twitter,
.fa-linkedin,
.fa-github,
.fa-instagram,
.fa-arrow-up-right,
.fa-medium,
.fa-dev {
    /* padding-right: 15px; */
    font-size: 24px;
    color: white;
}

.fa-medium {
    margin-left: -4px;
}

.overview-404 a {
    text-transform: capitalize;
}

@media screen and (max-width: 992px) {

    .dark .fa-envelope:hover,
    .dark .fa-skype:hover,
    .dark .fa-linkedin:hover,
    .dark .fa-twitter:hover,
    .dark .fa-github:hover,
    .dark .fa-instagram:hover,
    .dark .fa-arrow-up-right:hover,
    .dark .fa-medium:hover,
    .dark .fa-dev:hover {
        color: cornflowerblue !important;
    }

    .fa-envelope:hover,
    .fa-skype:hover,
    .fa-linkedin:hover,
    .fa-twitter:hover,
    .fa-github:hover,
    .fa-instagram:hover,
    .fa-arrow-up-right:hover,
    .fa-medium:hover,
    .fa-dev:hover {
        color: #292929 !important;
    }

    .about-git:hover,
.old .about-git:hover,
.dark .about-git:hover{
    color: #000 !important;
    transition: all 0.2s ease-in-out;
    translate: 0px 2px;
}

.dark .about-download:hover{
    color: cornflowerblue !important;

}

.about-download:hover,
.dark .about-download:hover,
.old .about-download:hover{
    color: cornflowerblue !important;
    
    transition: all 0.2s ease-in-out;
    translate: 0px 2px;
}


.reduced-motion .about-download:hover,
.reduced-motion .about-git:hover{
    transition: none !important;
    translate: 0px 0px !important;
}

    .social-container {
        padding-top: 0 !important;
        position: static;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 0;
        margin: 0;
        width: initial;
        height: 50px;
        background-color: transparent;

    }

    .main-footer,
    .footer-404 {
        /* position: fixed; */
        width: 100%;
        bottom: 0;
        height: 190px;
        /* margin-top:46px; */
        /* position:absolute;
left:0;
bottom:0;
right:0; */


    }

    .dark .main-footer .social-container,
    .dark .footer-404 .social-container {
        background-color: black;



    }



    .dark .main-footer p,
    .dark .footer-404 p {
        color: whitesmoke;
    }

    .sidebar-close {
        display: none;
    }
}