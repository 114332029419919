*{
    font-family: "Montserrat", sans-serif;
}

div.carousel-caption.testim-caption{
    font-size:0px;
    margin: 0 auto;
    /* border-radius:50%; */
    width:100%;
    height:100%;
    max-width:100%;
    max-height:100%;
    /* height:200px; */
    padding: 40px 120px !important;
    left:0;
    right:0;
    top:0;
    bottom:0;
    opacity:0;
}
.carousel-caption.testim-caption:hover{
    font-size:16px;
    display: flex;
    justify-content: center !important;
flex-direction: column !important;
    opacity:0.9;
    -webkit-transition: all 0.2s ease-in-out;
-o-transition: all 0.2s ease-in-out;
transition: all 0.2s ease-in-out;
}

.carousel-heading.testim-heading{
    font-size:18px;
    padding: 0 14px 0 14px;
    margin-bottom: 20px !important;
    
}


.more.testim-more{
    font-size:14px;
}


img.testim-image{
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    height:auto;
    opacity:0.9;
    border: 2px solid #529fcc;
}

.carousel-caption{
    font-size:0px;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    background-color: #529fcc;
    /* width: 100%; */
    padding:2.25rem 30px;
    right:0;
    left:0;
    bottom:0.25rem;
    border-radius: 15px;
}

.carousel-heading{
    font-size:18px;
    /* padding-top:1rem; */
}

.carousel-caption:hover,
    .carousel-caption:active{
    font-size:16px;
    transition: font-size 200ms ease-in-out;
}

.carousel-indicators {
bottom:-20px !important;
}

.carousel-indicators [data-bs-target]{
    background-color: #fff !important;
}

.carousel-control-prev-icon{
    background-image: url("../img/arrow-ios-back.svg") !important;

}

.carousel-control-next-icon{
    background-image: url("../img/arrow-ios-forward.svg") !important;

}

.dark .carousel-heading{
    color: whitesmoke !important;
}

a.more, a.testim-more{
    color: white !important;
}

.dark .more{
    font-size:14px;
    color: #ffd43b !important;
}

.dark .testim-more{
    font-size:14px;
    color: #ffd43b !important;
}




.dark .carousel-heading{
    color: #529fcc !important;
}

.carousel-heading{
    color: whitesmoke !important;
} 

.carousel-heading{
    color: whitesmoke !important;
}
.more, .testim-more{
    font-size:14px;
}

@media screen and (max-width: 980px){
    div.carousel-caption{
        font-size:0px;
        margin: 0 auto;
        width: 100%;
        max-width: 194px;
        /* height:200px; */
        left:0;
        right:0;
        
        /* opacity:0; */
    }
    .carousel-caption:hover{
        font-size:12px;
    }

    .carousel-heading{
        font-size:16px;
        padding: 0 14px 0 14px;
    }
    .more, .testim-more{
        font-size:12px;
    }
} 

@media screen and (max-width: 400px){
    div.carousel-caption{
        font-size:0px;
        margin: 0 auto;
        border-radius:50%;
        width:100%;
        height:100%;
        max-width:100%;
        max-height:100%;
        /* height:200px; */
        padding-left: 0 !important;
        padding-right: 0 !important;
        left:0;
        right:0;
        top:0;
        bottom:0;
        opacity:0;
    text-align: center !important;

    }
    .carousel-caption:hover{
        font-size:12px;
        display: flex;
        justify-content: center !important;
  flex-direction: column !important;
        opacity:0.9;
        -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    }

    .carousel-heading{
        font-size:18px;
        padding: 0 14px 0 14px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    div.active.carousel-item div.carousel-caption.testim-caption.carousel-caption h3.carousel-heading.testim-heading{
    text-align: center !important;
}
.testim-more.more{
    padding-left: 0 !important;
}
    
} 

