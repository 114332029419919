*{
    font-family: "Montserrat", sans-serif;
}

@property --CheckGradColor1 {
    syntax: "<color>";
    initial-value: #529fcc;
    inherits: false;
}

@property --CheckGradColor2 {
    syntax: "<color>";
    initial-value: #0e687a;
    inherits: false;
}

@property --ResumeGradColor1 {
    syntax: "<color>";
    initial-value: #111111;
    inherits: false;
}

@property --ResumeGradColor2 {
    syntax: "<color>";
    initial-value: #a3a3a3;
    inherits: false;
}

.container{
    padding-bottom:20px !important;
}

.h-100{
    height:100%;
    padding-bottom: 60px;
}

/* .dark .aboutAnimation {
    margin: auto;
    overflow: auto;
    background: linear-gradient(315deg, rgb(8, 1, 23) 3%, rgb(59, 61, 62) 38%, rgb(28, 29, 31) 68%, rgb(49, 49, 50) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

.aboutAnimation {
    margin: auto;
    overflow: auto;
    background: linear-gradient(315deg, rgb(254, 254, 255) 3%, rgb(229, 232, 235) 38%, rgb(226, 227, 232) 68%, rgb(241, 241, 246) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
}

@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
} */

.about-git:hover,
.old .about-git:hover,
.dark .about-git:hover{
    color: #000 !important;
    transition: all 0.2s ease-in-out;
    translate: 0px 2px;
}

.dark .about-download:hover{
    color: cornflowerblue;

}

.about-download:hover,
.dark .about-download:hover,
.old .about-download:hover{
    color: cornflowerblue;
    
    transition: all 0.2s ease-in-out;
    translate: 0px 2px;
}


.reduced-motion .about-download:hover,
.reduced-motion .about-git:hover{
    transition: none !important;
    translate: 0px 0px !important;
}



.wave {
    background: rgb(255 255 255 / 25%);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}


.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }

}

.loader-container {
    width: 100% !important;
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* position: fixed !important; */
    background: white;
    margin: auto !important;
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #529fcc transparent #529fcc transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}


.dark .loader-container {
    /* width: 100% !important; */
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: auto !important;
    background: #424242;
    z-index: 1;
}

.old .loader-container{
    background-color: #7b929f;;
}

.dark .spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #529fcc transparent #529fcc transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}


@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* scrollbar width */

::-webkit-scrollbar {
    width: 10px;
}

/* scrollbar Track */

::-webkit-scrollbar-track {
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(82, 159, 204, 1)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(82, 159, 204, 1) 100%);
    border-radius: 10px;
}

/* scrollbar Handle */

::-webkit-scrollbar-thumb {
    opacity: 0;
    -webkit-box-shadow: 0px 0px 0px 100000vh white;
            box-shadow: 0px 0px 0px 100000vh white;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(82, 159, 204, 1);
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.resumes{
    display:none;
}

.dark .r3play-carousel-heading{
    color: #529fcc;
} 

.flags{
    display:inline;
    text-decoration: none !important;
    border-bottom: none !important;
}

div.wrapper{
    min-height: 90vh;
    
}

.aboutContainer{
    flex-direction:row;
    
  }

  a.check-out:hover, a.check-out:active,
  button.resume:hover, button.resume:active,
  a.check-out:focus, button.resume:focus{
      /* padding-bottom: 6px; */
      outline: 1px solid #529eca;
      border-bottom: 2px solid transparent;
      -webkit-box-shadow: none;
              box-shadow: none;
  }
a.check-out {
    font-family: "Montserrat", sans-serif;
  /* height: 40px; */
  /* padding: 8px 18px; */
  /* margin-top: 10px; */
  /* margin-right: 8px; */
  /* display: inline-block; */
  background: #529fcc;
  /* background: -webkit-gradient(linear, left top, right top, color-stop(20%, var(--CheckGradColor1)), to(var(--CheckGradColor2)));
  background: -o-linear-gradient(left, var(--CheckGradColor1) 20%, var(--CheckGradColor2) 100%);
  background: linear-gradient(90deg, var(--CheckGradColor1) 20%, var(--CheckGradColor2) 100%);
  background: -webkit-linear-gradient(90deg, var(--CheckGradColor1) 20%, var(--CheckGradColor2) 100%); */
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#529FCC", endColorstr="#0E687A", GradientType=1 ); */
  border-radius: 8px;
  color: white;
  text-decoration: none;
  /* transition: --CheckGradColor1 0.3s ease-out, --CheckGradColor2 0.3s ease-out; */
}

a.check-out:hover {
    
    /* padding-bottom: 6px; */
    background: #3893c8;
    /* background: -webkit-gradient(linear, left top, right top, color-stop(20%, var(--CheckGradColor2)), to(var(--CheckGradColor1)));
    background: -o-linear-gradient(left, var(--CheckGradColor2) 20%, var(--CheckGradColor1) 100%);
    background: linear-gradient(90deg, var(--CheckGradColor2) 20%, var(--CheckGradColor1) 100%);
    background: -webkit-linear-gradient(90deg, var(--CheckGradColor2) 20%, var(--CheckGradColor1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#0E687A", endColorstr="#529FCC", GradientType=1 ); */
    color: white;
    /* --CheckGradColor1: #0e687a;
    --CheckGradColor2: #529fcc; */
}

button.resume {
    font-family: "Montserrat", sans-serif;
    /* height: 40px; */
    /* padding: 8px 18px; */
    /* margin-top: 10px; */
    /* margin-left: 8px; */
    /* display: inline-block; */
    background: #000;
    /* background: -webkit-gradient(linear, left top, right top, color-stop(30%, var(--ResumeGradColor1)), to(var(--ResumeGradColor2)));
    background: -o-linear-gradient(left, var(--ResumeGradColor1) 30%, var(--ResumeGradColor2) 100%);
    background: linear-gradient(90deg, var(--ResumeGradColor1) 30%, var(--ResumeGradColor2) 100%);
    background: -webkit-linear-gradient(90deg, var(--ResumeGradColor1) 30%, var(--ResumeGradColor2) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#111111", endColorstr="#a3a3a3", GradientType=1 ); */
    border-radius: 8px;
    color: white;
    text-decoration: none;
    /* transition: --ResumeGradColor1 0.3s ease-out, --ResumeGradColor2 0.3s ease-out; */
}



button.resume:hover {
    /* padding-bottom: 6px; */
    background: #222221;
    /* background: -webkit-gradient(linear, left top, right top, color-stop(30%, var(--ResumeGradColor2)), to(var(--ResumeGradColor1)));
    background: -o-linear-gradient(left, var(--ResumeGradColor2) 30%, var(--ResumeGradColor1) 100%);
    background: linear-gradient(90deg, var(--ResumeGradColor2) 30%, var(--ResumeGradColor1) 100%);
    background: -webkit-linear-gradient(90deg, var(--ResumeGradColor2) 30%, var(--ResumeGradColor1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#a3a3a3", endColorstr="#111111", GradientType=1 ); */
    color: white;
    /* --ResumeGradColor1: #a3a3a3;
    --ResumeGradColor2: #111111; */
}

a.check-out,
button.resume {
    font-size: 15px;
    height:36px !important;
    vertical-align: middle;
}

a.check-out {
    font-size: 15px;
    /* height:30px !important; */
    vertical-align: middle;
}

@-webkit-keyframes profile-picture {
    0% {
        -webkit-transform: translate(0px, 3px);
                transform: translate(0px, 3px);
    }

    50% {
        -webkit-transform: translate(0px, -3px);
                transform: translate(0px, -3px);
    }

    100% {
        -webkit-transform: translate(0px, 3px);
                transform: translate(0px, 3px);
    }
}

@keyframes profile-picture {
    0% {
        -webkit-transform: translate(0px, 3px);
                transform: translate(0px, 3px);
    }

    50% {
        -webkit-transform: translate(0px, -3px);
                transform: translate(0px, -3px);
    }

    100% {
        -webkit-transform: translate(0px, 3px);
                transform: translate(0px, 3px);
    }
}
.check-out.fade-in-up-on-scroll.ps-3.pe-1.btn.btn-primary{
padding-top:3px !important;
padding-bottom:6px !important;
}


.profile-picture {
    /* z-index: -1;
    border-radius: 50%; */
    width: 450px;
    max-width: 400px;
    height: auto;
    /* margin-right: 40px; */
    -webkit-animation: 4s profile-picture infinite;
            animation: 4s profile-picture infinite;
}

h1{
color: #529fcc;
    font-weight: bold;
    
}

@media screen and (min-width:481px) and (max-width:768px){
    .profile-picture {
        width:60%;
        margin: 0 auto;
    }
}

@media screen and (max-width:480px){
    .profile-picture {
        width:70%;
        margin: 0 auto;
    }
}

@media screen and (max-height: 604px){
    .social-container{
        position:static;
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
            -ms-flex-direction:row;
                flex-direction:row;
        padding:0 !important;
        margin:0;
        width:initial;
        height:50px;
        background-color: transparent;
        
    }

    .social-container .instagram{
       padding-left:0px;
    }

    .social-container .fa-medium{
        padding-right:10px;
     }

    .fa-envelope:hover,
.fa-skype:hover,
.fa-linkedin:hover,
.fa-twitter:hover,
.fa-github:hover,
.fa-instagram:hover,
.fa-medium:hover,
.fa-arrow-up-right:hover,
.fa-dev:hover {
    color: #292929 !important;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    translate: 0px -4px;
    -webkit-transition: color 0.2s ease, translate 0.3s linear;
    -o-transition: color 0.2s ease, translate 0.3s linear;
    transition: color 0.2s ease, translate 0.3s linear;
}


.dark .fa-envelope:hover,
.dark .fa-skype:hover,
.dark .fa-linkedin:hover,
.dark .fa-twitter:hover,
.dark .fa-github:hover,
.dark .fa-instagram:hover,
.dark .fa-medium:hover,
.dark .fa-arrow-up-right:hover,
.dark .fa-dev:hover {
    color: cornflowerblue !important;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    translate: 0px -4px;
    -webkit-transition: color 0.2s ease, translate 0.3s linear;
    -o-transition: color 0.2s ease, translate 0.3s linear;
    transition: color 0.2s ease, translate 0.3s linear;
}

.old .fa-envelope:hover,
.old .fa-skype:hover,
.old .fa-linkedin:hover,
.old .fa-twitter:hover,
.old .fa-github:hover,
.old .fa-instagram:hover,
.old .fa-medium:hover,
.old .fa-arrow-up-right:hover,
.old .fa-dev:hover {
    color: rgb(0, 0, 0) !important;
    -webkit-transition: color 0.2s ease;
    -o-transition: color 0.2s ease;
    transition: color 0.2s ease;
    translate: 0px -4px;
    -webkit-transition: color 0.2s ease, translate 0.3s linear;
    -o-transition: color 0.2s ease, translate 0.3s linear;
    transition: color 0.2s ease, translate 0.3s linear;
}


    .sidebar-close{
display: none;
    }
    .main-footer{
        height:200px;
    }
    .main-footer.dark .social-container, .footer-404.dark .social-container{
        background-color: #000;
    }


}
@media screen and (max-width:412px){
    .wrapper.contact-main_container.col, .wrapper.contact-main_container.col{
        padding-left:0;
        padding-right:0;
    }
    .about-block{
        padding: 18px 35px 26px 35px !important;
    }
    .contact-info, .blog-note, .projects-overview-paragraph, .slider-overview-paragraph, .fade-in-up-on-scroll, .r3play-takeaways, .poke-link{
        padding: 10px 30px;
    }
    .blog-image, .contact-image, img.projects-image{
        width:100% !important;
        margin-left:0;
        margin-right:0;

    }
    .project-carousel-sliders{
        padding-left:0 !important;
        padding-right: 0 !important;
    }
}

@media screen and (min-width:576px) and (max-width:677px){
    .container-fluid .col-md-4.col-sm-9.col-12 img.img-responsive.profile-picture.img-fluid.rounded-circle{
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

